var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"prlx",rawName:"v-prlx.background",value:({ 
			speed: .08,
			fromBottom: true 
		}),expression:"{ \n\t\t\tspeed: .08,\n\t\t\tfromBottom: true \n\t\t}",modifiers:{"background":true}}],attrs:{"id":"contatos"}},[_c('div',{staticClass:"wrap"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"caption text-center"},[_c('h4',{staticClass:"tit-mini-dest"},[_vm._v("Estamos aqui para responder suas dúvidas")]),_c('h1',{directives:[{name:"prlx",rawName:"v-prlx",value:({ 
                            reverse: true,
                            speed: 0.2,
                            preserveInitialPosition: false,
                            limit: { min: 0 },
                            fromBottom: false
                        }),expression:"{ \n                            reverse: true,\n                            speed: 0.2,\n                            preserveInitialPosition: false,\n                            limit: { min: 0 },\n                            fromBottom: false\n                        }"}]},[_vm._v(" ENTRE EM CONTATO ")])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }