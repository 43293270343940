<template>
    
    <section id="contatos"
        v-prlx.background="{ 
			speed: .08,
			fromBottom: true 
		}">
        <div class="wrap">
            <b-row>
                <b-col cols="12">
                    <div class="caption text-center">
                        <h4 class="tit-mini-dest">Estamos aqui para responder suas dúvidas</h4>
                        <h1 v-prlx="{ 
                            reverse: true,
                            speed: 0.2,
                            preserveInitialPosition: false,
                            limit: { min: 0 },
                            fromBottom: false
                        }">
                             ENTRE EM CONTATO
                        </h1>
                    </div>
                </b-col>
            </b-row>
        </div>
    </section>

</template>

<script>
    export default {
        data(){
            return{

            }
        },
        methods:{
        
        },  
    }
</script>

<style scoped lang="scss">
    @import "../assets/scss/global";

    section{
        background: url('../assets/images/bg-cta.jpg') no-repeat center;
        background-size: cover;
        height: 100%;
        padding: 150px 0;
        min-height: auto;
    }
    strong{
        color: $white;
    }
    h1{
        color: $white;
        font-weight: 900;
        @media (max-width: 500px){
            font-size: 45px !important;
            line-height: 45px !important;
        }
    }

</style>    